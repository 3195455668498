.rw-wrapper {
    width: 80%;
    position: relative;
    margin: 110px auto 0 auto;
    font-family: 'Bree Serif';
    padding: 10px;
  }
  
  .rw-words {
    display: inline;
    text-indent: 10px;
  }
  
  .rw-words-1 h2 {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    color: #6b969d;
    -webkit-animation: rotateWord 18s linear infinite 0s;
    -ms-animation: rotateWord 18s linear infinite 0s;
    animation: rotateWord 18s linear infinite 0s;
  }
  
  .rw-words-1 h2:nth-child(2) {
    -webkit-animation-delay: 3s;
    -ms-animation-delay: 3s;
    animation-delay: 3s;
    color: #6b889d;
  }
  
  .rw-words-1 h2:nth-child(3) {
    -webkit-animation-delay: 6s;
    -ms-animation-delay: 6s;
    animation-delay: 6s;
    color: #6b739d;
  }
  
  .rw-words-1 h2:nth-child(4) {
    -webkit-animation-delay: 9s;
    -ms-animation-delay: 9s;
    animation-delay: 9s;
    color: #7a6b9d;
  }
  
  .rw-words-1 h2:nth-child(5) {
    -webkit-animation-delay: 12s;
    -ms-animation-delay: 12s;
    animation-delay: 12s;
    color: #8d6b9d;
  }
  
  .rw-words-1 h2:nth-child(6) {
    -webkit-animation-delay: 15s;
    -ms-animation-delay: 15s;
    animation-delay: 15s;
    color: #9b6b9d;
  }
  
  @-webkit-keyframes rotateWord {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 0;
      -webkit-transform: translateY(-30px);
    }
    5% {
      opacity: 1;
      -webkit-transform: translateY(0px);
    }
    17% {
      opacity: 1;
      -webkit-transform: translateY(0px);
    }
    20% {
      opacity: 0;
      -webkit-transform: translateY(30px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-ms-keyframes rotateWord {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 0;
      -ms-transform: translateY(-30px);
    }
    5% {
      opacity: 1;
      -ms-transform: translateY(0px);
    }
    17% {
      opacity: 1;
      -ms-transform: translateY(0px);
    }
    20% {
      opacity: 0;
      -ms-transform: translateY(30px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes rotateWord {
    0% {
      opacity: 0;
    }
    2% {
      opacity: 0;
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    5% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    17% {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    20% {
      opacity: 0;
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @media screen and (max-width: 768px) {
    .rw-sentence {
      font-size: 18px;
    }
    
  }
  
  @media screen and (max-width: 520px) {
    .rw-sentence {
      font-size: 9px;
    }
    .rw-words {

    }
    
  }